




































































import Vue from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/ru'
import { formattingDateForRequest } from '@/mixins/mixins'
import NavigateBack from '@/components/NavigateBack.vue'
import Loading from '@/components/Loading/index.vue'
import ModalDownload from '@/components/Report/ModalDowload.vue'
import ModalSendEmail from '@/components/Report/ModalSendEmail.vue'
import TableListOfProfiles from '@/components/Report/TableListOfProfiles.vue'

export default Vue.extend({
  name: 'Reports',

  mixins: [formattingDateForRequest],

  components: {
    TableListOfProfiles,
    ModalSendEmail,
    ModalDownload,
    NavigateBack,
    DatePicker,
    Loading
  },

  data () {
    return {
      isDownloadModalShown: false,
      isSendModalShown: false,
      reportRangeDate: null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        },
        monthBeforeYear: false
      },
      isLoading: false,
      offset: 0,
      page: 1,
      limit: 10,
      searchValue: '',
      selectedIds: []
    }
  },

  watch: {
    $route (to, from) {
      this.offset = Number(to.query.offset || 0)
      this.searchValue = to.query.search || ''
      if (from.query.offset !== to.query.offset || from.query.search !== to.query.search) {
        this.getReport()
      }
      if (to.query.order_transactions_from_date && to.query.order_transactions_to_date) {
        this.reportRangeDate = [to.query.order_transactions_from_date, to.query.order_transactions_to_date]
      }
    }
  },

  async mounted () {
    await this.getAvalableDates()

    this.page = Number(this.$route.query.page || 1)
    this.offset = Number(this.$route.query.offset || 1)
    this.searchValue = this.$route.query.search || ''
    if (this.$route.query.order_transactions_from_date && this.$route.query.order_transactions_to_date) {
      this.reportRangeDate = [this.$route.query.order_transactions_from_date, this.$route.query.order_transactions_to_date]
      await this.getReport()
    }
  },

  beforeDestroy () {
    this.reportRangeDate = null
    this.clearSelectedProfiles()
  },

  computed: {
    ...mapState('reports', [
      'availableDataRage',
      'selectedProfiles'
    ]),
    ...mapState('profiles', [
      'businesssProfiles',
      'businesssProfilesCount'
    ]),
    defaultDate () {
      if (!this.availableDataRage?.toDate) {
        return new Date().setDate(new Date().getDate() - 1)
      }
      return new Date(this.availableDataRage.toDate).setMonth(new Date(this.availableDataRage.toDate).getMonth() - 1)
    }
  },

  methods: {
    ...mapActions('reports', [
      'getAvailableDataRange'
    ]),

    ...mapActions('profiles', [
      'getBisinessProfiles'
    ]),
    ...mapMutations('reports', [
      'addSelectedProfile',
      'removeSelectedProfile',
      'clearSelectedProfiles'
    ]),

    disabledRange (date) {
      const today = new Date()

      // if we haven't to_date it's mean that on the DB no data of the transactions
      if (!this.availableDataRage || !this.availableDataRage?.toDate) {
        return true
      }

      return date < new Date(this.availableDataRage?.fromDate || today) || date > new Date(this.availableDataRage?.toDate)
    },

    readableDate (dateNumeric) {
      if (!dateNumeric) return ''
      const date = new Date(dateNumeric)
      return date.toLocaleString('ru-RU', { year: 'numeric', month: 'numeric', day: 'numeric' })
    },

    async getAvalableDates () {
      this.isLoading = true
      const result = await this.getAvailableDataRange()
      if (result.status < 200 || result.status >= 300) {
        this.$toast.error(result.data.detail.message)
      }
      this.isLoading = false
    },

    async getReport () {
      if (this.reportRangeDate.length !== 2) return this.$toast.error('Выберите интервал времени')
      this.isLoading = true
      const fromDate = this.formattingDateForRequest(this.reportRangeDate[0])
      const toDate = this.formattingDateForRequest(this.reportRangeDate[1])

      const result = await this.getBisinessProfiles({
        order_transactions_from_date: fromDate,
        order_transactions_to_date: toDate,
        offset: this.offset,
        limit: this.limit,
        filter_fields: ['username', 'phone', 'business__contact_phone'],
        filter_value: this.searchValue
      })
      this.isLoading = false
      if (result.status < 200 || result.status >= 300) {
        this.$toast.error(result.data.detail?.message || 'Произошла ошибка. Попробуйте попозже.')
      }
    },
    changeTab (tabNumber) {
      this.page = tabNumber
      this.offset = (this.page - 1) * this.limit
      this.setRouteQuery('page', this.page)
      this.setRouteQuery('offset', this.offset)
    },
    setRouteQuery (paramName: string, paramValue) {
      const query = { ...this.$route.query, [paramName]: paramValue }

      this.$router.push({ query }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
    },
    handleRangeDate (value) {
      if (value.length !== 2) return
      this.setRouteQuery('order_transactions_from_date', value[0])
      this.setRouteQuery('order_transactions_to_date', value[1])
    },
    handleGenerateReport () {
      this.searchValue = ''
      this.offset = 0
      this.setRouteQuery('search', '')
      this.setRouteQuery('offset', 0)
      this.setRouteQuery('page', 0)
      this.clearSelectedProfiles()
      this.getReport()
    },
    handleDowloadAction (selectedIds: []) {
      this.selectedIds = selectedIds
      this.isDownloadModalShown = true
    },
    handleSendAction (selectedIds: []) {
      this.selectedIds = selectedIds
      this.isSendModalShown = true
    }
  }
})
