






























import Vue, { PropType } from 'vue'
import { mapActions } from 'vuex'
import { formattingDateForRequest } from '@/mixins/mixins'

export default Vue.extend({
  name: 'ModalDownload',

  mixins: [formattingDateForRequest],

  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    selectedIds: {
      type: Array as PropType<object>,
      default: () => ([])
    }
  },

  data () {
    return {
      showed: false,
      pdf: false,
      xls: true,
      title: 'Скачать документ', // Выберете формат документа
      isResult: null
    }
  },

  watch: {
    value (val) {
      this.showed = val
    }
  },

  mounted () {
    this.showed = this.value
  },

  methods: {
    ...mapActions('reports', [
      'uploadMassReport'
    ]),

    handleClick () {
      if (this.isResult) return this.closeModal()
      this.handleDowload()
    },

    async handleDowload () {
      const result = await this.uploadMassReport({
        from_date: this.formattingDateForRequest(this.$route.query.order_transactions_from_date),
        to_date: this.formattingDateForRequest(this.$route.query.order_transactions_to_date),
        business_profile_ids: this.selectedIds
      })
      this.isResult = true
      if (result.status < 200 || result.status >= 300) {
        this.$toast.error(result.data.detail?.message || 'Произошла ошибка. Попробуйте попозже.')
        this.title = 'Ошибка при скачивании. Попробуйте позже.'
        return
      }
      if (result.data) {
        window.location.assign(result.data)
      }
      this.title = 'Отчет успешно скачан!'
      this.$emit('success')
    },

    closeModal () {
      this.title = 'Выберете формат документа'
      this.isResult = false
      this.$emit('modal-hide')
    }
  }
})
