
















































import Vue, { PropType } from 'vue'
import { mapActions } from 'vuex'
import { formattingDateForRequest } from '@/mixins/mixins'
import ValidatedBFormInput from '@/components/ui/ValidatedBFormInput.vue'

export default Vue.extend({
  name: 'ModalSendEmail',

  mixins: [formattingDateForRequest],

  components: {
    ValidatedBFormInput
  },

  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    selectedIds: {
      type: Array as PropType<object>,
      default: () => ([])
    }
  },

  data () {
    return {
      showed: false,
      email: '',
      title: 'Отправить отчёт',
      isResult: null,
      pdf: false,
      xls: true
    }
  },

  watch: {
    value (val) {
      this.showed = val
    }
  },

  mounted () {
    this.showed = this.value
  },

  methods: {
    ...mapActions('reports', [
      'uploadMassReportByEmail'
    ]),

    handleClick () {
      if (this.isResult) return this.closeModal()
      this.handleSend()
    },

    async handleSend () {
      const isValid = await this.$refs.observer.validate()

      if (!isValid) return this.$toast.error('Проверьте правильность введенных данных')

      const result = await this.uploadMassReportByEmail({
        subject: 'Отчёт по транзакциям.',
        body: 'Здравствуйте! Направляем отчёт по транзакциям.',
        recipient: this.email,
        from_date: this.formattingDateForRequest(this.$route.query.order_transactions_from_date),
        to_date: this.formattingDateForRequest(this.$route.query.order_transactions_to_date),
        business_profile_ids: this.selectedIds
      })
      this.isResult = true
      if (result.status < 200 || result.status >= 300) {
        this.$toast.error(result.data.detail?.message || 'Произошла ошибка. Попробуйте попозже.')
        this.title = 'Ошибка отправки. Попробуйте позже.'
        return
      }
      this.title = 'Отчет успешно отправлен на указанный адрес!'
      this.$emit('success')
    },

    closeModal () {
      this.title = 'Отправить отчёт'
      this.isResult = false
      this.$emit('modal-hide')
    }
  }
})
