














































































































































































import Vue, { PropType } from 'vue'
import { Profile } from '@/store/modules/profiles/types'
import Search from '@/components/Search/Search.vue'
import windowWidth from '@/mixins/windowWidth'
import { TheMask, mask } from 'vue-the-mask'
import { cloneDeep } from '@/utils/functions'

export default Vue.extend({
  name: 'TableListOfProfiles',
  mixins: [windowWidth],
  components: {
    Search,
    TheMask
  },
  directives: {
    mask
  },
  props: {
    profiles: {
      type: Array as PropType<Profile[]>,
      default: () => ([])
    },
    count: {
      type: Number as PropType<number>,
      default: 0
    },
    limit: {
      type: Number as PropType<number>,
      default: 0
    },
    selectedProfiles: {
      type: Array as PropType<string[]>,
      default: () => ([])
    }
  },
  data () {
    return {
      offset: 0,
      searchPlaceholder: 'Поиск по номеру телефона',
      searchValue: '',
      fields: [
        {
          key: 'select',
          label: '',
          thStyle: { width: '1%' }
        },
        {
          key: 'username',
          label: 'Логин'
        },
        {
          key: 'name',
          label: 'Имя бизнеса'
        },
        {
          key: 'id',
          label: 'ID учетной записи'
        },
        {
          key: 'phone',
          label: 'Основной номер'
        },
        {
          key: 'contact_phone',
          label: 'Контактный телефон'
        },
        {
          key: 'actions',
          label: '',
          thStyle: { width: '10%' }
        }
      ],
      selectedIds: [],
      localProfiles: []
    }
  },
  watch: {
    searchValue (value) {
      if (!value.length && this.$route.query?.search?.length) {
        this.clearSearchValue()
      }
    },
    selectedProfiles (selectedProfiles) {
      if (!selectedProfiles.length) {
        this.clearSelectOnProfiles()
      }
    },
    profiles (profiles) {
      if (profiles.length) {
        this.localProfiles = cloneDeep(this.profiles)
        this.localProfiles = this.profiles.map(profile => {
          return {
            ...profile,
            selected: !!this.selectedProfiles.includes(profile.id)
          }
        })
      }
    }
  },
  async mounted () {
    this.searchValue = this.$route.query.search || ''
  },

  methods: {
    async handleSearch () {
      if (!this.searchValue.trim()) {
        this.$refs['search-input'].$el.focus()
        return
      }
      this.$emit('setRouteQuery', 'search', this.searchValue)
      this.$emit('setRouteQuery', 'offset', 0)
      this.$emit('setRouteQuery', 'page', 1)
      this.handleClearSelect()
    },

    async clearSearchValue () {
      this.searchValue = ''
      this.$emit('setRouteQuery', 'search', '')
      this.$emit('setRouteQuery', 'offset', 0)
      this.$emit('setRouteQuery', 'page', 1)
      this.handleClearSelect()
    },
    showDetails (profile: any) {
      if (profile.profile_type === 'admin') return
      this.$router.push({ name: 'profile-detail', params: { profileId: profile.id } })
    },

    dowload (selectedIds) {
      this.$emit('dowloadAction', selectedIds)
    },

    send (selectedIds) {
      this.$emit('sendAction', selectedIds)
    },

    handleChangeSelect (profile) {
      if (profile.selected) return this.$emit('addSelectedProfile', profile.profile_data.id)
      return this.$emit('removeSelectedProfile', profile.profile_data.id)
    },
    clearSelectOnProfiles () {
      this.localProfiles = this.localProfiles.map(profile => {
        return {
          ...profile,
          selected: false
        }
      })
    },
    handleClearSelect () {
      this.clearSelectOnProfiles()
      this.$emit('clearSelectedProfiles')
    }
  }
})
